import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";

/* HOME PAGE */
import HomePage from "./pages/Home/Home";

/* STORE */
import StoreHome from "./pages/Listings/Store/StoreHome";
import StoreProduct from "./pages/Listings/Store/StoreListing";
import EditProduct from "./pages/Listings/Store/ProductEdit/ProductEdit";

/* AUTO */
import AutoHomeNew from "./pages/Listings/Autos/AutosNewHome";
import AutoListingNew from "./pages/Listings/Autos/AutoListing-new";
import EditAutoListing from "./pages/Listings/Autos/AutosEdit/AutosEdit";

/* FOOD */
import FoodHome from "./pages/Listings/Food/FoodHome";
import FoodItem from "./pages/Listings/Food/FoodListing";
import EditFood from "./pages/Listings/Food/FoodEdit/FoodEdit";

/* REAL ESTATE */
import RealEstateHome from "./pages/Listings/RealEstate/RealEstateHome";
import RealEstateProperty from "./pages/Listings/RealEstate/PropertyListing";
import EditProperty from "./pages/Listings/RealEstate/PropertyEdit/PropertyEdit";

/* JOBS */
import JobsHome from "./pages/Listings/Jobs/JobsHome";
import JobsPosting from "./pages/Listings/Jobs/JobListing";
import EditJobPosting from "./pages/Listings/Jobs/JobEdit/JobEdit";

/* SERVICES */
import ServicesHome from "./pages/Listings/Services/ServicesHome";
import ServiceDetail from "./pages/Listings/Services/ServiceListing";
import EditService from "./pages/Listings/Services/ServiceEdit/ServiceEdit";

/* TRAVEL */
import TravelHome from "./pages/Listings/Travel/TravelHome";
import TravelListing from "./pages/Listings/Travel/TravelListing";
import TravelEdit from "./pages/Listings/Travel/TravelEdit/TravelEdit";

/* SPECIALS */
import Specials from "./shared/components/Advertising/Specials/Specials";

/* USER PROFILE */
import UserProfile from "./pages/Profile/UserProfile";
import Favorites from "./pages/Profile/ToolsUser/Favorites/Favorites";
import ProfileAbout from "./pages/Profile/UserProfile/Pages/ProfileAbout";
import ProfileReviews from "./pages/Profile/UserProfile/Pages/ProfileReviews";
import ProfileStore from "./pages/Profile/UserProfile/Pages/ProfileStore";
import ProfileSocial from "./pages/Profile/UserProfile/Pages/ProfileSocial";

/* USER PASSWORD */
import ForgotPassword from "./pages/Profile/Auth/ForgotPassword/ForgotPassword";
import ResetPassword from "./pages/Profile/Auth/ResetPassword/ResetPassword";

/* NOTIFICATIONS */
import Notifications from "./pages/Profile/ToolsUser/Notifications/Notifications";
import FollowRequests from "./pages/Profile/ToolsUser/Notifications/FollowRequests";

/* CHAT */
import Chat from "./pages/Profile/ToolsUser/Chat/chat";

/* USER SETTINGS */
import ProfileUpdate from "./pages/Profile/ProfileSettings/ProfileUpdate/ProfileUpdate";
import AccountSettings from "./pages/Profile/ProfileSettings/AccountSettings/AccountSettings";
import ListingSettings from "./pages/Profile/ProfileSettings/ListingSettings/ListingSettings";
import SubscriptionSettings from "./pages/Profile/ProfileSettings/SubscriptionSettings/SubscriptionSettings";
import PurchaseHistory from "./pages/Profile/ProfileSettings/PurchaseHistory/PurchaseHistory";
import PrivacySettings from "./pages/Profile/ProfileSettings/PrivacySettings/PrivacySettings";
import InviteFriends from "./pages/Profile/ProfileSettings/InviteFriends/InviteFriends";
import PostsHistory from "./pages/Profile/ProfileSettings/EditListings/EditListings";
import ManageCoupons from "./pages/Profile/ProfileSettings/ManageCoupons/ManageCoupons";
import ManagePromotions from "./pages/Profile/ProfileSettings/ManagePromotions/ManagePromotions";
import CashInSettings from "./pages/Profile/ProfileSettings/CashIn/CashIn";
import ResellerAffiliateDashboard from "./pages/Profile/ProfileSettings/ResellerAffiliateDashboard/ResellerAffiliateDashboard";
import DeleteAccount from "./pages/Profile/ProfileSettings/DeleteAccount/DeleteAccount";
import SellerDashboard from "./pages/Profile/ToolsAdmin/Seller";
import SuperAdminPage from "./pages/Profile/ToolsAdmin/Admin";
import ItemListAdmin from "./pages/Profile/ToolsAdmin/ItemList";
// import ManageRealestate from "./pages/Profile/ToolsAdmin/ManageRealestate";

/* BLOGS */
import BlogsHome from "./pages/Social/Blogs/BlogsHome";
import BlogPost from "./pages/Social/Blogs/BlogListing";
import EditBlog from "./pages/Social/Blogs/BlogEdit";

/* PRESS */
import EditPress from "./pages/Static/Press/PressEdit";

/* PHOTOS */
import PhotosHome from "./pages/Social/Photos/PhotosHome";
import PhotoGallery from "./pages/Social/Photos/PhotoGalleryListing";
import EditPhoto from "./pages/Social/Photos/PhotoGalleryEdit";

/* VIDEOS */
import VideosHome from "./pages/Social/Videos/VideosHome";
import VideoDetail from "./pages/Social/Videos/VideoListing";
import EditVideo from "./pages/Social/Videos/VideoEdit";

/* EVENTS */
import EventsHome from "./pages/Listings/Events/EventsHome";
import EventsListing from "./pages/Listings/Events/EventListing";
import EventsEdit from "./pages/Listings/Events/EventEdit/EventEdit";

/* GROUPS */
import GroupsHome from "./pages/Social/Groups/GroupsHome";
import GroupsListing from "./pages/Social/Groups/GroupListing/GroupListing";
import GroupsEdit from "./pages/Social/Groups/GroupEdit";

/* NEWS */
import NewsHome from "./pages/Social/News/NewsHome";
import NewsListing from "./pages/Social/News/NewsListing";
import NewsEdit from "./pages/Social/News/NewsEdit";

/* ABOUT */
import About from "./pages/Static/About/About";
import MeetTheTeam from "./pages/Static/MeetTheTeam/MeetTheTeam";
import Directory from "./pages/Static/Directory/Directory";
import Help from "./pages/Static/Help/Help";
import Careers from "./pages/Static/Careers/Careers";
import CareerApplication from "./pages/Static/Careers/Application/CareerApplication";
import Press from "./pages/Static/Press/Press";
import Dealer from "./pages/Static/Dealer/Dealer";

/* PARTNERS */
import Affiliates from "./pages/Static/Affiliates/Affiliates";
import CashInApplication from "./pages/Static/Affiliates/CashInApplication/CashInApplication";
import ResellerApplication from "./pages/Static/Affiliates/ResellerApplication/ResellerApplication";
import Investors from "./pages/Static/Investors/Investors";
import SmallBusinesses from "./pages/Static/SmallBusinesses/SmallBusinesses";

/* DOWNLOAD APP */
import DownloadApp from "./pages/Static/DownloadApp/DownloadApp";

/* BOTTOM MENU */
import Privacy from "./pages/Static/Privacy/Privacy";
import Cookie from "./pages/Static/Cookie/Cookie";
import CodeOfConduct from "./pages/Static/CodeOfConduct/CodeOfConduct";
import TermsOfService from "./pages/Static/TermsOfService/TermsOfService";
import CopyrightNotice from "./pages/Static/CopyrightNotice/CopyrightNotice";
import Contact from "./pages/Static/Contact/Contact";

/* 404 */
import NotFound from "./pages/Static/404/404";

/* SEARCH */
import SearchResults from "./pages/Search/SearchResults";

/* STYLEGUIDE */
import StyleGuide from "./shared/util/styleguide/StyleGuide";

/* ADVERTISING */
import Advertise from "./pages/Static/Advertise/Advertise";
import PayPerView from "./shared/components/Advertising/PayPerView/PayPerView";
import Referral from "./shared/components/Advertising/Referral/Referral";

/* COUPONS */
// import CouponsHome from './pages/Coupons/CouponsHome';
// import EditCoupon from './pages/Coupons/EditCoupon';

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path="/" component={HomePage} />
          <Route path="/styleguide" component={StyleGuide} />
          <Route
            path="/about/executives-and-directors"
            component={MeetTheTeam}
          />
          <Route path="/about" component={About} />

          {/* <Route path="/directory" component={Directory} /> */}
          <Route path="/help" component={Help} />
          <Route path="/careers/apply" component={CareerApplication} />
          <Route path="/careers" component={Careers} />

          <Route path="/affiliates" component={Affiliates} />
          <Route path="/investors" component={Investors} />
          <Route path="/start-ups" component={SmallBusinesses} />
          <Route path="/advertise" component={Advertise} />
          <Route path="/privacy" component={Privacy} />
          <Route path="/cookies" component={Cookie} />
          <Route path="/terms-of-service" component={TermsOfService} />
          <Route path="/contact" component={Contact} />
          <Route path="/dealer/:dealerId" component={Dealer} />
          <Route path="/dealer" component={Dealer} />
          <Route path="/copyright" component={CopyrightNotice} />
          <Route path="/downloadapp" component={DownloadApp} />
          <Route path="/affiliateurl/:userid" component={Referral} />

          <Route path="/create-ppv-campaign" component={PayPerView} />

          <Route path="/chat/:conversationId" component={Chat} />
          <Route path="/chat" component={Chat} />

          <Route path="/jkdf943i9grjr90gjr" component={SuperAdminPage} />
          <Route path="/lkgjlkje9030reqwir" component={ItemListAdmin} />
          {/* <Route path="/gheivkjdi934jk49fs" component={ManageRealestate} /> */}
          <Route path="/hfsdfsjfkjsafj4848j" component={SellerDashboard} />
          <Route path="/profile-update" component={ProfileUpdate} />
          <Route path="/invite-friends" component={InviteFriends} />
          <Route path="/cash-in" component={CashInSettings} />
          <Route path="/account-settings" component={AccountSettings} />
          <Route path="/listing-settings" component={ListingSettings} />
          <Route path="/privacy-settings" component={PrivacySettings} />
          <Route path="/code-of-conduct" component={CodeOfConduct} />
          <Route
            path="/subscription-settings"
            component={SubscriptionSettings}
          />
          <Route path="/delete-account" component={DeleteAccount} />
          <Route path="/manage-coupons" component={ManageCoupons} />
          <Route path="/purchase-history" component={PurchaseHistory} />
          <Route path="/posts-history" component={PostsHistory} />
          <Route path="/manage-promotions" component={ManagePromotions} />
          <Route path="/follow-requests" component={FollowRequests} />
          <Route path="/notifications" component={Notifications} />
          <Route
            path="/reseller-affiliate-dashboard"
            component={ResellerAffiliateDashboard}
          />
          <Route path="/specials" component={Specials} />
          <Route path="/cash-in-application" component={CashInApplication} />
          <Route path="/reseller-application" component={ResellerApplication} />
          <Route path="/forgot-password" component={ForgotPassword} />
          <Route path="/reset-password" component={ResetPassword} />
          <Route path="/search" component={SearchResults} />
          <Route path="/settings-menu" component={ProfileUpdate} />

          <Route exact path="/blogs" component={HomePage} />
          <Route exact path="/photos" component={HomePage} />
          <Route exact path="/videos" component={HomePage} />
          <Route exact path="/groups" component={HomePage} />
          <Route exact path="/events" component={HomePage} />

          <Route exact path="/travel" component={TravelHome} />
          <Route exact path="/store" component={StoreHome} />
          <Route exact path="/food" component={FoodHome} />
          <Route exact path="/autos" component={AutoHomeNew} />
          <Route exact path="/realestate" component={RealEstateHome} />
          <Route exact path="/jobs" component={JobsHome} />
          <Route exact path="/services" component={ServicesHome} />
          <Route exact path="/news" component={NewsHome} />
          {/* <Route exact path="/coupons" component={NotFound} /> */}

          <Route path="/blogs/new-blog" exact component={EditBlog} />
          <Route path="/blogs/edit-blog" exact component={EditBlog} />

          <Route path="/press/new-press" exact component={EditPress} />
          <Route path="/press/edit-press" exact component={EditPress} />
          <Route path="/press" component={Press} />

          <Route path="/photos/new-photo" exact component={EditPhoto} />
          <Route path="/photos/edit-photo" exact component={EditPhoto} />
          <Route path="/videos/new-video" exact component={EditVideo} />
          <Route path="/videos/edit-video" exact component={EditVideo} />
          <Route path="/store/new-product" exact component={EditProduct} />
          <Route path="/store/edit-product" exact component={EditProduct} />
          <Route path="/food/new-food-listing" exact component={EditFood} />
          <Route path="/food/edit-food-listing" exact component={EditFood} />
          <Route
            path="/autos/new-auto-listing"
            exact
            component={EditAutoListing}
          />
          <Route
            path="/autos/edit-auto-listing"
            exact
            component={EditAutoListing}
          />
          <Route
            path="/realestate/new-property"
            exact
            component={EditProperty}
          />
          <Route
            path="/realestate/edit-property"
            exact
            component={EditProperty}
          />
          <Route path="/jobs/new-job" exact component={EditJobPosting} />
          <Route path="/jobs/edit-job" exact component={EditJobPosting} />
          <Route path="/services/new-service" exact component={EditService} />
          <Route path="/services/edit-service" exact component={EditService} />
          {/* <Route path="/coupons/new-coupon" component={EditCoupon} />
            <Route path="/coupons/edit-coupon" component={EditCoupon} /> */}
          <Route path="/events/new-event" exact component={EventsEdit} />
          <Route path="/events/edit-event" exact component={EventsEdit} />
          <Route path="/groups/new-group" exact component={GroupsEdit} />
          <Route path="/groups/edit-group" exact component={GroupsEdit} />
          <Route path="/travel/new-travel" exact component={TravelEdit} />
          <Route path="/travel/edit-travel" exact component={TravelEdit} />
          <Route path="/news/new-news" exact component={NewsEdit} />
          <Route path="/news/edit-news" exact component={NewsEdit} />

          {/* <Route path="/coupons/search/:storeQuery" component={CouponsHome} />
            <Route path="/coupons/:storeCategory" component={CouponsHome} /> */}
          <Route path="/blogs/search/:storeQuery" component={BlogsHome} />
          <Route
            path="/blogs/:storeCategory/:storeSubcategory/:storeItem"
            component={BlogPost}
          />
          <Route
            path="/blogs/:storeCategory/:storeSubcategory"
            component={BlogsHome}
          />
          <Route path="/blogs/:storeCategory" component={BlogsHome} />
          <Route path="/photos/search/:storeQuery" component={PhotosHome} />
          <Route
            path="/photos/:storeCategory/:storeSubcategory/:storeItem"
            component={PhotoGallery}
          />
          <Route
            path="/photos/:storeCategory/:storeSubcategory"
            component={PhotosHome}
          />
          <Route path="/photos/:storeCategory" component={PhotosHome} />
          <Route path="/videos/search/:storeQuery" component={VideosHome} />
          <Route
            path="/videos/:storeCategory/:storeSubcategory/:storeItem"
            component={VideoDetail}
          />
          <Route
            path="/videos/:storeCategory/:storeSubcategory"
            component={VideosHome}
          />
          <Route path="/videos/:storeCategory" component={VideosHome} />
          <Route path="/groups/search/:storeQuery" component={GroupsHome} />
          <Route
            path="/groups/:storeCategory/:storeSubcategory/:storeItem"
            component={GroupsListing}
          />
          <Route
            path="/groups/:storeCategory/:storeSubcategory"
            component={GroupsHome}
          />
          <Route path="/groups/:storeCategory" component={GroupsHome} />

          <Route path="/events/search/:storeQuery" component={EventsHome} />
          <Route
            path="/events/:storeCategory/:storeSubcategory/:storeItem"
            component={EventsListing}
          />
          <Route
            path="/events/:storeCategory/:storeSubcategory"
            component={EventsHome}
          />
          <Route path="/events/:storeCategory" component={EventsHome} />
          <Route path="/store/search/:storeQuery" component={StoreHome} />
          <Route
            path="/store/:storeCategory/:storeSubcategory/:storeItem"
            component={StoreProduct}
          />
          <Route
            path="/store/:storeCategory/:storeSubcategory"
            component={StoreHome}
          />
          <Route path="/store/:storeCategory" component={StoreHome} />
          <Route path="/food/search/:storeQuery" component={FoodHome} />
          <Route
            path="/food/:storeCategory/:storeSubcategory/:storeItem"
            component={FoodItem}
          />
          <Route
            path="/food/:storeCategory/:storeSubcategory"
            component={FoodHome}
          />
          <Route path="/food/:storeCategory" component={FoodHome} />
          <Route path="/autos/search/:storeQuery" component={AutoHomeNew} />
          <Route
            path="/autos/:storeCategory/:storeSubcategory/:storeItem"
            component={AutoListingNew}
          />
          <Route
            path="/autos/:storeCategory/:storeSubcategory"
            component={AutoHomeNew}
          />
          <Route path="/autos/:storeCategory" component={AutoHomeNew} />

          <Route
            path="/realestate/search/:storeQuery"
            component={RealEstateHome}
          />
          <Route
            path="/realestate/:storeCategory/:storeSubcategory/:storeItem"
            component={RealEstateProperty}
          />
          <Route
            path="/realestate/:storeCategory/:storeSubcategory"
            component={RealEstateHome}
          />
          <Route path="/realestate/:storeCategory" component={RealEstateHome} />
          <Route path="/jobs/search/:storeQuery" component={JobsHome} />
          <Route
            path="/jobs/:storeCategory/:storeSubcategory/:storeItem"
            component={JobsPosting}
          />
          <Route
            path="/jobs/:storeCategory/:storeSubcategory"
            component={JobsHome}
          />
          <Route path="/jobs/:storeCategory" component={JobsHome} />
          <Route path="/services/search/:storeQuery" component={ServicesHome} />
          <Route
            path="/services/:storeCategory/:storeSubcategory/:storeItem"
            component={ServiceDetail}
          />
          <Route
            path="/services/:storeCategory/:storeSubcategory"
            component={ServicesHome}
          />
          <Route path="/services/:storeCategory" component={ServicesHome} />
          <Route path="/travel/search/:storeQuery" component={TravelHome} />
          <Route
            path="/travel/:storeCategory/:storeSubcategory/:storeItem"
            component={TravelListing}
          />
          <Route
            path="/travel/:storeCategory/:storeSubcategory"
            component={TravelHome}
          />
          <Route path="/travel/:storeCategory" component={TravelHome} />
          <Route path="/news/search/:storeQuery" component={NewsHome} />
          <Route
            path="/news/:storeCategory/:storeSubcategory/:storeItem"
            component={NewsListing}
          />
          <Route
            path="/news/:storeCategory/:storeSubcategory"
            component={NewsHome}
          />
          <Route path="/news/:storeCategory" component={NewsHome} />
          {/* <Route path="/favorites" component={Favorites} /> */}

          <Route exact path="/404" component={NotFound} />

          <Route
            path="/:userprofile/store/search/:storeQuery"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/store/:storeCategory/:storeSubcategory"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/store/:storeCategory"
            component={UserProfile}
          />

          <Route
            path="/:userprofile/autos/search/:storeQuery"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/autos/:storeCategory/:storeSubcategory"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/autos/:storeCategory"
            component={UserProfile}
          />

          <Route
            path="/:userprofile/food/search/:storeQuery"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/food/:storeCategory/:storeSubcategory"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/food/:storeCategory"
            component={UserProfile}
          />

          <Route
            path="/:userprofile/realestate/search/:storeQuery"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/realestate/:storeCategory/:storeSubcategory"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/realestate/:storeCategory"
            component={UserProfile}
          />

          <Route
            path="/:userprofile/jobs/search/:storeQuery"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/jobs/:storeCategory/:storeSubcategory"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/jobs/:storeCategory"
            component={UserProfile}
          />

          <Route
            path="/:userprofile/services/search/:storeQuery"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/services/:storeCategory/:storeSubcategory"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/services/:storeCategory"
            component={UserProfile}
          />

          <Route
            path="/:userprofile/travel/search/:storeQuery"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/travel/:storeCategory/:storeSubcategory"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/travel/:storeCategory"
            component={UserProfile}
          />

          <Route
            path="/:userprofile/blogs/search/:storeQuery"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/blogs/:storeCategory/:storeSubcategory"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/blogs/:storeCategory"
            component={UserProfile}
          />

          <Route
            path="/:userprofile/photos/search/:storeQuery"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/photos/:storeCategory/:storeSubcategory"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/photos/:storeCategory"
            component={UserProfile}
          />

          <Route
            path="/:userprofile/videos/search/:storeQuery"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/videos/:storeCategory/:storeSubcategory"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/videos/:storeCategory"
            component={UserProfile}
          />

          <Route
            path="/:userprofile/events/search/:storeQuery"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/events/:storeCategory/:storeSubcategory"
            component={UserProfile}
          />
          <Route
            path="/:userprofile/events/:storeCategory"
            component={UserProfile}
          />

          {/* <Route path="/:userprofile/wall" component={UserProfile} /> - COMMING SOON  */}
          <Route path="/:userprofile/about" component={UserProfile} />
          <Route path="/:userprofile/reviews" component={UserProfile} />
          <Route path="/:userprofile/favorites" component={UserProfile} />

          <Route path="/:userprofile/store" component={UserProfile} />

          <Route path="/:userprofile/autos" component={UserProfile} />

          <Route path="/:userprofile/food" component={UserProfile} />
          <Route path="/:userprofile/realestate" component={UserProfile} />
          <Route path="/:userprofile/jobs" component={UserProfile} />
          <Route path="/:userprofile/services" component={UserProfile} />
          <Route path="/:userprofile/events" component={UserProfile} />
          <Route path="/:userprofile/travel" component={UserProfile} />
          <Route path="/:userprofile/blogs" component={UserProfile} />
          <Route path="/:userprofile/photos" component={UserProfile} />
          <Route path="/:userprofile/videos" component={UserProfile} />
          <Route path="/:userprofile/groups" component={UserProfile} />

          <Route path="/:userprofile" component={UserProfile} />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
